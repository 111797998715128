import React from 'react';
import { View, TextInput, Text, Platform } from 'react-native';
import { Picker, PickerIOS } from '@react-native-picker/picker';
import { useTranslation } from 'react-i18next';
import DeliveryType from 'types/models/DeliveryType';
import LocalDeliveryArea from 'types/models/LocalDeliveryArea';
import LocalDeliveryField from 'types/models/LocalDeliveryField';
import DeliveryMethodProps from 'types/components/DeliveryMethodProps';
import OrderContext from 'contexts/OrderContext';
import DeliveryTypeBtn from './DeliveryTypeBtn';
import { styles } from 'styles/components/deliveryMethod';

export default ({
  selectedDelivery,
  setSelectedDelivery,
  deliveryConfig,
  editable = true,
  buttonWasPressed,
  inputEmpty,
}: DeliveryMethodProps): JSX.Element => {
  const { t } = useTranslation();
  const originalDeliveryTypes = [
    { id: 1, title: t('pick-up'), icon: 'smile', selected: true },
    {
      id: 2,
      title: deliveryConfig?.isLocalDelivery ? t('in-seat-delivery') : t('delivery'),
      icon: 'sign-out-alt',
      selected: !deliveryConfig?.pickUpEnabled ? true : false,
    },
  ];
  const [deliveryTypes, setDeliveryTypes] = React.useState<DeliveryType[]>(originalDeliveryTypes);
  const { currentShoppingCart, setCurrentShoppingCart, currentCommerce } = React.useContext(OrderContext);
  const [deliveryInput, setDeliveryInput] = React.useState<string>('');
  const [selectedAreaDescription, setSelectedAreaDescription] = React.useState<string>('');
  const [selectedArea, setSelectedArea] = React.useState<LocalDeliveryArea | null>(null);
  const [complexDeliveryInputs, setComplexDeliveryInputs] = React.useState<ComplexDeliveryInput[] | null>(null);

  const sortLocalDeliveryFields = (a: LocalDeliveryField, b: LocalDeliveryField): number => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  React.useEffect(() => {
    if (currentShoppingCart && currentShoppingCart.delivery) setDeliveryInput(currentShoppingCart.delivery);
    // Set Initial Delivery Type
    let initialDeliveryType = 1;
    if (deliveryConfig.deliveryEnabled) {
      if (currentShoppingCart?.delivery || !deliveryConfig.pickUpEnabled) initialDeliveryType = 2;
    }
    toggleDeliveryType(initialDeliveryType);

    if (currentCommerce && currentCommerce.type.description === 'Parking') {
      setSelectedAreaDescription(deliveryConfig.areas[0].description);
    }
  }, []);

  const toggleDeliveryType = (id: number) => {
    let filteredOptionsFromDeliveryTypes = [...originalDeliveryTypes];

    // Si pickup is disabled lo saco
    if (!deliveryConfig.pickUpEnabled) {
      filteredOptionsFromDeliveryTypes = filteredOptionsFromDeliveryTypes.filter((option) => option.id !== 1);
    }

    // Si delivery is disabled lo saco
    if (!deliveryConfig.deliveryEnabled) {
      filteredOptionsFromDeliveryTypes = filteredOptionsFromDeliveryTypes.filter((option) => option.id !== 2);
    }

    setDeliveryTypes(filteredOptionsFromDeliveryTypes);

    const deliveryType = filteredOptionsFromDeliveryTypes.find((deliveryType) => deliveryType.id === id);
    if (!deliveryType || deliveryType.id === selectedDelivery?.id) return;
    setSelectedDelivery(deliveryType);
  };

  const changeDeliveryInput = (delivery: string) => {
    if (currentShoppingCart) {
      setDeliveryInput(delivery);
      setCurrentShoppingCart({ ...currentShoppingCart, delivery });
    }
  };

  const changeComplexDeliveryInput = (
    complexDeliveryInput: ComplexDeliveryInput,
    localDeliveryField: LocalDeliveryField,
    newValue: string,
  ) => {
    complexDeliveryInput.error = '';

    // Controlamos que el campo es requerido.
    if (!newValue && localDeliveryField.isRequired) {
      complexDeliveryInput.error = `${t('field')} ${localDeliveryField.description} ${t("it-can't-be-empty")}`;
    }

    // Controles para campo number
    if (newValue && localDeliveryField.isNumber) {
      const value = parseFloat(newValue);
      if (isNaN(value)) {
        complexDeliveryInput.error = `${t('field')} ${localDeliveryField.description} ${t('must-be-a-number')}`;
      } else if (localDeliveryField.minNumberValue && value < localDeliveryField.minNumberValue) {
        complexDeliveryInput.error = `${t('the-number-of')} ${localDeliveryField.description} ${t(
          'must-be-greater-than',
        )} ${localDeliveryField.minNumberValue}`;
      } else if (localDeliveryField.maxNumberValue && value > localDeliveryField.maxNumberValue) {
        complexDeliveryInput.error = `${t('the-number-of')} ${localDeliveryField.description} ${t(
          'must-be-less-than',
        )} ${localDeliveryField.maxNumberValue}`;
      }
    }

    if (!complexDeliveryInputs) return;
    complexDeliveryInput.value = newValue;
    setComplexDeliveryInputs([...complexDeliveryInputs]);
    let delivery = '';
    const error = complexDeliveryInputs.find((complexDeliveryInput) => complexDeliveryInput.error);
    if (!error) {
      const filledFields = complexDeliveryInputs.filter((complexDeliveryInput) => complexDeliveryInput.value !== '');
      if (deliveryConfig.areas.length > 1 && selectedArea) {
        delivery += `${selectedArea.description}, `;
      }
      filledFields.forEach((field, index) => {
        delivery += `${field.description}: ${field.value}`;
        if (index !== filledFields.length - 1) {
          delivery += ', ';
        }
      });
    }
    if (currentShoppingCart) {
      setCurrentShoppingCart({ ...currentShoppingCart, delivery });
    }
  };

  React.useEffect(() => {
    if (!currentShoppingCart) return;
    if (selectedDelivery?.id === 1) {
      delete currentShoppingCart.delivery;
      setCurrentShoppingCart({ ...currentShoppingCart });
      setDeliveryInput('');
    }
  }, [selectedDelivery]);

  React.useEffect(() => {
    if (selectedAreaDescription) {
      const selectArea = () => {
        const area = deliveryConfig.areas.find((area) => area.description === selectedAreaDescription);
        if (area) {
          setSelectedArea(area);
          area.fields.sort(sortLocalDeliveryFields);
          setComplexDeliveryInputs(
            area.fields.map((field) => ({
              id: field.id,
              value: '',
              description: field.description,
              error: '',
            })),
          );
        }
      };
      selectArea();
    }
  }, [selectedAreaDescription]);

  const renderDeliveryToAddress = (): JSX.Element => (
    <>
      <Text style={styles.subTitle}>{t('address')}</Text>
      <TextInput
        keyboardType="default"
        value={deliveryInput}
        onChangeText={changeDeliveryInput}
        style={[
          styles.deliveryValueInput,
          !editable && styles.disabledDeliveryInput,
          { outlineStyle: 'none' },
          buttonWasPressed && inputEmpty && styles.inputIsRequired,
        ]}
        editable={editable}
        placeholder={t('address-placeholder')}
        placeholderTextColor="#C4C4C4"
      />
    </>
  );

  const getPlaceholder = (localDeliveryField: LocalDeliveryField): string => {
    if (localDeliveryField.placeholder) {
      return localDeliveryField.placeholder;
    }
    return `${t('delivery-enter-your-data')} ${localDeliveryField.description}`;
  };

  const renderDeliveryToLocal = (): JSX.Element => (
    <>
      {currentCommerce?.type.description === 'Parking' ? (
        <View style={{ marginTop: 10 }}>
          <Text style={styles.sectionTitle}>{deliveryConfig.areas[0].description}</Text>
        </View>
      ) : null}
      <Text style={styles.warningPerField}>{`${t('fields-required')} *`}</Text>
      {deliveryConfig.areas.length > 0 && currentCommerce?.type.description !== 'Parking' && (
        <View style={styles.pickerContainer}>
          <Text style={styles.subTitle}>{t('select-an-area')}</Text>
          {Platform.OS !== 'ios' ? (
            <Picker
              style={[
                styles.picker,
                { outlineStyle: 'none' },
                buttonWasPressed && inputEmpty && styles.inputIsRequired,
                buttonWasPressed && inputEmpty && selectedAreaDescription === '' && styles.inputTextRequired,
              ]}
              selectedValue={selectedAreaDescription}
              onValueChange={(value) => {
                setSelectedAreaDescription(value);
              }}
            >
              {selectedAreaDescription === '' && <Picker.Item label={t('area-in-selection')} value={-1} />}
              {deliveryConfig.areas.map((localDeliveryArea, index) => (
                <Picker.Item key={index} label={localDeliveryArea.description} value={localDeliveryArea.description} />
              ))}
            </Picker>
          ) : (
            <PickerIOS
              style={[
                styles.pickerIos,
                { outlineStyle: 'none' },
                buttonWasPressed && inputEmpty && styles.inputIsRequired,
                buttonWasPressed && inputEmpty && selectedAreaDescription === '' && styles.inputTextRequired,
              ]}
              selectedValue={selectedAreaDescription}
              onValueChange={(value) => {
                setSelectedAreaDescription(value.toString());
              }}
              itemStyle={{ height: 80, padding: 10 }}
            >
              {selectedAreaDescription === '' && <Picker.Item label={t('area-in-selection')} value={''} />}
              {deliveryConfig.areas.map((localDeliveryArea, index) => (
                <Picker.Item key={index} label={localDeliveryArea.description} value={localDeliveryArea.description} />
              ))}
            </PickerIOS>
          )}
        </View>
      )}
      {selectedArea && complexDeliveryInputs && (
        <>
          {selectedArea.fields.map((localDeliveryField, index) => (
            <View key={index} style={{ marginTop: 10 }}>
              <Text style={styles.subTitle}>
                {localDeliveryField.description}
                {localDeliveryField.isRequired && <Text style={styles.warningPerField}> * </Text>}
              </Text>
              <TextInput
                keyboardType={localDeliveryField.isNumber ? 'number-pad' : 'default'}
                value={complexDeliveryInputs[index].value}
                onChangeText={(newValue) => {
                  changeComplexDeliveryInput(complexDeliveryInputs[index], localDeliveryField, newValue);
                }}
                style={[
                  styles.deliveryValueInput,
                  buttonWasPressed && !complexDeliveryInputs[index].value && styles.inputIsRequired,
                  !editable && styles.disabledDeliveryInput,
                  { outlineStyle: 'none' },
                ]}
                editable={editable}
                placeholder={getPlaceholder(localDeliveryField)}
                placeholderTextColor={buttonWasPressed && !complexDeliveryInputs[index].value ? '#F00' : '#C4C4C4'}
              />
              {complexDeliveryInputs[index].error !== '' && (
                <View>
                  <Text style={styles.invalidatedField}>{complexDeliveryInputs[index].error}</Text>
                </View>
              )}
            </View>
          ))}
        </>
      )}
    </>
  );

  return (
    <>
      {currentCommerce?.type.description !== 'Parking' ? (
        <View style={styles.deliveryTypeBtnsContainer}>
          {deliveryTypes.map((deliveryType) => (
            <DeliveryTypeBtn
              key={deliveryType.id}
              deliveryType={deliveryType}
              onPress={() => {
                if (!currentShoppingCart?.processed) {
                  toggleDeliveryType(deliveryType.id);
                }
              }}
            />
          ))}
        </View>
      ) : null}
      {selectedDelivery && selectedDelivery.id === 2 && (
        <>
          {selectedDelivery.title === t('delivery') && renderDeliveryToAddress()}
          {selectedDelivery.title === t('in-seat-delivery') && renderDeliveryToLocal()}
        </>
      )}
    </>
  );
};

interface ComplexDeliveryInput {
  id: string;
  value: any;
  description: string;
  error: string;
}
