import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  deliveryTypeBtnsContainer: {
    marginTop: 15,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  subTitle: {
    fontSize: 14,
    color: '#000',
  },
  deliveryValueInput: {
    borderColor: '#89909b',
    borderWidth: 1,
    backgroundColor: '#FFF',
    padding: 10,
    height: 54,
    fontSize: 14,
    borderRadius: 5,
  },
  disabledDeliveryInput: {
    backgroundColor: '#89909b',
    color: '#FFF',
  },
  invalidatedField: {
    color: '#F00',
    fontSize: 16,
    marginTop: 5,
  },
  warningPerField: {
    color: '#F00',
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5,
  },
  pickerContainer: {
    marginTop: 5,
    marginBottom: 6,
  },
  picker: {
    borderColor: '#89909b',
    borderWidth: 1,
    backgroundColor: '#FFF',
    padding: 10,
    height: 54,
    fontSize: 14,
    borderRadius: 5,
  },
  pickerIos: {
    fontSize: 14,
  },
  pickerSubTitle: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: '#89909b',
    marginTop: 5,
    marginBottom: 5,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 10,
    lineHeight: 20,
  },
  inputIsRequired: {
    borderColor: '#F00',
  },
  inputTextRequired: {
    color: '#F00',
  },
});
